import { subscribeToNotifications } from "../services/api";
import { getAuthToken } from "../services/auth";

// src/utils/pushNotification.js
const NOTIFICATION_PREFERENCE_KEY = "notification_preference";

const waitForServiceWorkerReady = (timeout = 5000) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error("Service Worker took too long to become ready"));
    }, timeout);

    navigator.serviceWorker.ready
      .then((registration) => {
        clearTimeout(timer);
        resolve(registration);
      })
      .catch(reject);
  });
};

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js"
      );
      return registration;
    } catch (error) {
      console.error("Service Worker registration failed:", error);
      throw error;
    }
  } else {
    throw new Error("Service Worker not supported in this browser");
  }
};

const checkExistingSubscription = async () => {
  try {
    const registration = await waitForServiceWorkerReady();
    const subscription = await registration.pushManager.getSubscription();
    return subscription !== null;
  } catch (error) {
    console.error("Error checking subscription:", error);
    return false;
  }
};

const getUserHasDeniedNotifications = () => {
  return localStorage.getItem(NOTIFICATION_PREFERENCE_KEY) === "denied";
};

const setUserNotificationPreference = (preference) => {
  localStorage.setItem(NOTIFICATION_PREFERENCE_KEY, preference);
};

const subscribeUser = async () => {
  try {
    const registration = await waitForServiceWorkerReady();

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BCu9c-gBSZPJ8gFjEjqyFglNBuLbKN-nRNA8Sw4esJy27P0WgNgsk4kMoNgQxcmsymE8gj1U1HRtalZKedxGF5s",
    });

    await sendSubscriptionToServer(subscription);
    return subscription;
  } catch (error) {
    console.error("Error subscribing to push notifications:", error);
    throw error;
  }
};

export const checkNotificationStatus = async () => {
  try {
    // Don't proceed if not in a browser environment or user is not logged in
    if (typeof window === "undefined" || !getAuthToken()) {
      return "unauthenticated";
    }

    // Check if notifications are supported
    if (!("Notification" in window)) {
      return "unsupported";
    }

    // Check if previously denied
    if (getUserHasDeniedNotifications()) {
      return "denied";
    }

    // Check current permission
    const permission = Notification.permission;
    if (permission === "denied") {
      setUserNotificationPreference("denied");
      return "denied";
    }

    // Check if subscribed
    const hasSubscription = await checkExistingSubscription();
    if (hasSubscription) {
      return "subscribed";
    }

    return permission; // 'granted' or 'default'
  } catch (error) {
    console.error("Error checking notification status:", error);
    return "error";
  }
};

export const initializeNotifications = async () => {
  try {
    // Don't proceed if not in a browser environment or user is not logged in
    if (typeof window === "undefined" || !getAuthToken()) {
      return false;
    }

    // Check if notifications are supported
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications");
      return false;
    }

    // Check if user has previously denied notifications
    if (getUserHasDeniedNotifications()) {
      console.log("User previously denied notifications");
      return false;
    }

    // Check if already subscribed
    const hasSubscription = await checkExistingSubscription();
    if (hasSubscription) {
      return true;
    }

    // Register service worker
    await registerServiceWorker();

    // Check current permission status
    const currentPermission = Notification.permission;

    if (currentPermission === "denied") {
      setUserNotificationPreference("denied");
      return false;
    }

    if (currentPermission === "granted") {
      const subscription = await subscribeUser();
      return !!subscription;
    }

    // If permission is default (not yet asked), request it
    const permission = await Notification.requestPermission();
    setUserNotificationPreference(permission);

    if (permission === "granted") {
      const subscription = await subscribeUser();
      return !!subscription;
    }

    return false;
  } catch (error) {
    console.error("Error initializing notifications:", error);
    return false;
  }
};

const sendSubscriptionToServer = async (subscription) => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("No authentication token found");
    }

    subscribeToNotifications(subscription);
  } catch (error) {
    console.error("Error sending subscription to server:", error);
    throw error;
  }
};
