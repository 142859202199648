import axios from "axios";
import { getAuthToken } from "./auth";

export const API_BASE_URL = "https://api.agroaivision.com";

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// * Herd Counting
const cache = {
  farms: null,
  counts: {},
};

export const fetchFarms = async () => {
  if (cache.farms) return cache.farms;

  try {
    const response = await api.get("/farms");
    cache.farms = response.data;
    return response.data;
  } catch (error) {
    console.error("Error fetching farms:", error);
    throw error;
  }
};

export const fetchCounts = async (
  page = 1,
  sortField = "date",
  sortDirection = "desc",
  farmId = null
) => {
  const cacheKey = `${page}-${sortField}-${sortDirection}-${farmId}`;

  if (cache.counts[cacheKey]) return cache.counts[cacheKey];

  try {
    const response = await api.get("/counts", {
      params: { page, sortField, sortDirection, farmId },
    });
    cache.counts[cacheKey] = response.data;
    return response.data;
  } catch (error) {
    console.error("Error fetching counts:", error);
    throw error;
  }
};

export const fetchPurchasedProjects = async () => {
  try {
    const response = await api.get("/projects");
    return response.data.projectIds || [];
  } catch (error) {
    console.error("Failed to fetch purchased projects:", error);
    throw error;
  }
};

export const subscribeToNotifications = async (subscription) => {
  try {
    if (subscription) {
      await api.post("/notifications/push-subscription", subscription);
    }
  } catch (error) {
    console.error("Failed to subscribe to push notifications:", error);
    throw error;
  }
};
